<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select v-model="dataForm.orgId" :disabled="disabled">
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司" prop="companyId">
          <el-select v-model="dataForm.companyId" :disabled="disabled">
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="优惠率" prop="discount">
          <el-input
            v-model="dataForm.discount"
            :disabled="disabled"
            placeholder="优惠率"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCompanyList, getOrgList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        orgId: '',
        companyId: '',
        discount: '',
      },
      orgList: [],
      companyList: [],
      dataRule: {
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        discount: [
          { required: true, message: '优惠率不能为空', trigger: 'blur' },
        ],
      },
    };
  },

  created() {
    this.getOrgList();
    this.getCompanyList();
  },

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgcompanydiscount/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.orgcompanydiscount, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgcompanydiscount/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            }
          });
        }
      });
    },

    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
  },
};
</script>
